import React, { useEffect, useState } from "react";
import { fetchReaderOptions } from "../picklist-verification/utils/fetch-reader-options";
import OrderCreationSetup from "./features/order-creation-setup";
import { TRANSFER_ORDER_STAGES } from "../../data/constants";
import OrderCreationSubmissionWorkflow from "./features/order-creation-submission-workflow";
import MainPage from "../../../main-page";
import useTransferOrderActionsConfigContext from "../../contexts/transfer-order-actions-config-context";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import { analyzeItemsForItemTypeReport } from "../../../../../../utils/item-type-report/itemTypeReportHelpers";

export const OrderCreation = () => {
  const { defaultStageId } = useTransferOrderActionsConfigContext();
  const {
    itemByTag,
    setItemTypeReports,
    setReaderOptions,
    setLoading,
    itemTypeReports,
    isSubmitting,
    setIsSubmitting,
    panelValues,
    setPanelValues
  } = useKioskStateContext();
  const [existingTransferOrder, setExistingTransferOrder] = useState(undefined);
  const [newTransferOrderIdentifier, setNewTransferOrderIdentifier] = useState("");
  const [existingContainer, setExistingContainer] = useState(undefined);
  const [newContainer, setNewContainer] = useState(undefined);

  useEffect(() => {
    if (!itemByTag) {
      return;
    }

    const reports = analyzeItemsForItemTypeReport(Object.values(itemByTag));
    setItemTypeReports(reports);
  }, [itemByTag]);

  const initializeOrderCreation = async (transferOrder, transferOrderIdentifier, container, containerToCreate) => {
    setLoading(true);
    setExistingTransferOrder(transferOrder);
    setNewTransferOrderIdentifier(transferOrderIdentifier);
    setExistingContainer(container);
    setNewContainer(containerToCreate);

    setPanelValues({
      ...panelValues,
      transferOrderIdentifiersString: transferOrder?.identifier || transferOrderIdentifier
    });
    const readerOptions = await fetchReaderOptions(defaultStageId);

    setReaderOptions(readerOptions);
    setLoading(false);
  };

  return (
    <>
      {newTransferOrderIdentifier ? (
        <MainPage
          onClearReport={() => {
            setItemTypeReports([]);
          }}
          onClearReportRow={(id) => {
            const reportIndex = itemTypeReports.findIndex((report) => {
              return report.id === id;
            });

            const newReports = [...itemTypeReports];
            newReports.splice(reportIndex, 1);
            setItemTypeReports(newReports);
          }}
        />
      ) : (
        <OrderCreationSetup onConfirm={initializeOrderCreation} />
      )}
      {isSubmitting && (
        <OrderCreationSubmissionWorkflow
          existingTransferOrder={existingTransferOrder}
          newTransferOrderIdentifier={newTransferOrderIdentifier}
          existingContainer={existingContainer}
          newContainer={newContainer}
          onClose={() => {
            setIsSubmitting(false);
          }}
          stage={TRANSFER_ORDER_STAGES[defaultStageId]}
        />
      )}
    </>
  );
};
