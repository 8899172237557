import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import useKioskConfigContext from "../../../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../../../contexts/kiosk-state-context";
import { STATUS_OPTIONS } from "../../../../../../../../../../components/status-popup-component/data/constants";
import { KIOSK, KIOSK_STEPS } from "../../../../../../../../../../constants/mixpanel-constant/kiosk";
import useMixpanelContext from "../../../../../../../../../../context/mixpanel-context";
import SubmissionConfirmationModal from "./features/submission-confirmation";
import usePrintService from "../../../../../../../../../../hooks/use-print-service";
import getTransferOrderUpdates from "./utils/get-transfer-order-updates";
import useTransferOrderActionsConfigContext from "../../../../contexts/transfer-order-actions-config-context";
import submitTransferOrderAction from "../../../../../../../../utils/submit-transfer-order-action";
import ContainerAssociationModal from "../../../container-association-modal";
import useKioskSearchParams from "../../../../../../../../hooks/use-kiosk-search-params";

export const PicklistVerificationSubmissionWorkflow = ({
  onClose,
  transferOrders,
  stage,
  existingContainer,
  newContainer
}) => {
  const { setActionId } = useKioskSearchParams();
  const { sendMixPanelEvent } = useMixpanelContext();
  const { setSubmitStatus, setStatusMessage, panelValues, itemTypeReports } = useKioskStateContext();
  const { action, printOptions } = useKioskConfigContext();
  const { containerAssociationOptions } = useTransferOrderActionsConfigContext();

  const [showContainerAssociation, setShowContainerAssociation] = useState(containerAssociationOptions.enabled);

  const printService = usePrintService(printOptions.enabled, printOptions.printType, printOptions.printConfig, "kiosk");
  const { printItems, printTime } = printService;

  const numItems = useMemo(() => {
    return itemTypeReports.reduce((acc, curr) => {
      return acc + curr.quantityScanned;
    }, 0);
  }, [itemTypeReports]);

  const onConfirm = async () => {
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage(`Submitting ${action.label}`);

    const detectorId = panelValues.readerLocation?.detectorId;

    try {
      const params = {
        existingTransferOrders: transferOrders,
        existingContainer,
        newContainer,
        stage,
        itemTypeReports,
        detectorId,
        printOptions,
        printTime,
        printItems,
        actionType: action.actionType,
        containerTypeIdentifier: containerAssociationOptions.containerTypeIdentifier
      };

      await submitTransferOrderAction(params);

      setSubmitStatus(STATUS_OPTIONS.SUCCESS);
      setStatusMessage(`The items have been verified.`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_SUCCESSFUL, { action: action.actionType });
    } catch (e) {
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      setStatusMessage(`Failed to verify the items. Please contact Xemelgo Support for assistance.`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_FAILED, {
        action: action.actionType,
        error: JSON.stringify(e)
      });
    }

    setActionId();
  };

  return showContainerAssociation ? (
    <ContainerAssociationModal
      numItems={numItems}
      transferOrderIdentifier={panelValues.transferOrderIdentifiersString}
      onConfirm={() => {
        setShowContainerAssociation(false);
      }}
      onCancel={onClose}
      stageActionLabel={stage.actionLabel}
    />
  ) : (
    <SubmissionConfirmationModal
      onCancel={onClose}
      onConfirm={onConfirm}
      stageStatus={stage.statusLabel}
      containerIdentifier={newContainer?.identifier || existingContainer?.identifier}
      transferOrderUpdates={getTransferOrderUpdates(transferOrders, itemTypeReports, stage)}
    />
  );
};

PicklistVerificationSubmissionWorkflow.defaultProps = {
  transferOrders: [],
  existingContainer: undefined,
  newContainer: undefined
};

PicklistVerificationSubmissionWorkflow.propTypes = {
  onClose: PropTypes.func.isRequired,
  transferOrders: PropTypes.arrayOf(PropTypes.shape({})),
  stage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    actionLabel: PropTypes.string.isRequired,
    statusLabel: PropTypes.string.isRequired,
    submitStatus: PropTypes.string.isRequired
  }).isRequired,
  existingContainer: PropTypes.shape({
    identifier: PropTypes.string
  }),
  newContainer: PropTypes.shape({
    identifier: PropTypes.string,
    vid: PropTypes.string
  })
};
